.header {
    .botao-menu-expander {
        color: white;
    }

    .nivel-1 {
        padding: 3px;
        color: white;
        background-color: $secondary !important;
    }

    .nivel-2 {
        padding: 10px;
        background-color: $primary;

        .logo-topo {
            margin: 10px 0px;
            max-width: 296px;
            will-change: transform;
            transition: all 0.5s ease;
            max-height: 88px;

            @media (max-width: 991px) {
                max-width: 180px;
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                max-width: 230px;
            }

            &:hover,
            &:focus {
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }

        .img-buscar {
            will-change: transform;
            transition: all 0.5s ease;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }
    }

    .btn-xs-botao {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .nav-item {
        padding-left: 50px;

        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 0px;
        }

        .nav-link {
            color: white !important;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0px;
                opacity: 0;
                border-radius: 6px;
                height: 3px;
                left: 50%;
                transition: all 0.5s ease;
            }

            &:hover,
            &:focus {
                transition: all 0.5s ease;

                &::before {
                    width: 100%;
                    left: 0;
                    transition: all 0.5s ease;
                    opacity: 1;
                }
            }
        }

        .rota-ativa {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &:hover,
            &:focus {
                text-decoration: none !important;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 100%;
                opacity: 1;
                border-radius: 6px;
                height: 3px;
                transition: all 0.5s ease;
            }
        }
    }

    .aux-padding-categorias {
        padding-right: 15px;
        padding-left: 15px;

        @media (max-width: 560px) {
            padding: 0px;
        }
    }
}

.header-2 {
    visibility: hidden;
    z-index: 0;
    opacity: 0;
    position: fixed;
    transition: all 0.5s ease;
    width: 100%;
    top: 0px;
    background-color: #fff;

    .btn-xs-botao {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .nivel-1 {
        padding: 3px;
        color: white;
        background-color: $secondary !important;
    }

    .nivel-2 {
        padding: 10px;
        background-color: $primary;

        @media (max-width: 991px) {
            padding: 1rem 0rem;
        }

        .logo-topo {
            margin: 10px 0px;
            max-width: 296px;
            will-change: transform;
            transition: all 0.5s ease;
            max-height: 135px;

            @media (max-width: 991px) {
                max-width: 180px;
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                max-width: 230px;
            }

            &:hover,
            &:focus {
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }

        .img-buscar {
            will-change: transform;
            transition: all 0.5s ease;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }
    }

    .nav-item {
        padding-left: 50px;

        @media (max-width: 991px) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 0px;
        }

        .nav-link {
            color: white !important;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0px;
                opacity: 0;
                border-radius: 6px;
                height: 3px;
                left: 50%;
                transition: all 0.5s ease;
            }

            &:hover,
            &:focus {
                transition: all 0.5s ease;

                &::before {
                    width: 100%;
                    left: 0;
                    transition: all 0.5s ease;
                    opacity: 1;
                }
            }
        }

        .rota-ativa {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &:hover,
            &:focus {
                text-decoration: none !important;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 100%;
                opacity: 1;
                border-radius: 6px;
                height: 3px;
                transition: all 0.5s ease;
            }
        }
    }

    .aux-padding-categorias {
        padding-right: 15px;
        padding-left: 15px;

        @media (max-width: 560px) {
            padding: 0px;
        }
    }
}

.btn-padrao {
    transition: all 0.5s ease;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;

    i {
        font-size: 20px !important;
    }
}

.transition-navbar {
    transition: all 0.5s ease;
}

.et_pb_menu__search-container,
.et_pb_menu .et_pb_menu__search-container {
    @media (max-width: 991px) {
        position: relative;
    }
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    transition: all 0.5s ease;
}

.et_pb_menu__search {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    form {
        width: 100%;
    }
}

.magnifyColor {
    font-size: 30px;
}

.faTimes {
    font-size: 20px;
    margin-right: 10px;
}
