.section-beneficios-home {
    margin-bottom: 40px;

    .title-section {
        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: $primary;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .card-beneficio {
        .box-absolute-beneficio {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;

            i {
                font-size: 23px;
                margin-left: 5px;
                margin-top: 2px;
            }
        }

        .box-foto-beneficio {
            height: 100px;
            display: flex;
            justify-content: center;
            .foto-beneficio {
                width: 70px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 15px;
                will-change: transform;
            }
        }

        .conteudo-beneficio {
            .titulo-beneficio {
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: $primary;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;

                @media (max-width: 991px) {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}
