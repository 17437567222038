.contrasFilterBlack {
    filter: brightness(0) saturate(100%);
}

.contrastFilter {
    filter: brightness(0) invert(1);
}

.container-banner {
    position: relative;
    .imagem {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: calc(100vh - 302.53px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 150px;

        @media (max-width: 991px) {
            height: 300px;
            justify-content: center !important;
            padding-left: 0px !important;
        }
    }

    .descricao-banner {
        overflow: hidden;
        font-size: 50px;
        font-style: normal;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        width: 400px;
        z-index: 2;
        color: #fff;
        padding: 10px;
        opacity: 0;
        transform: translateY(80px);
        transition: all 0.5s ease;

        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 33px;
            padding: 5rem 4rem;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 35px;
            line-height: 48px;
        }

        .link-banner {
            margin-top: 1rem;

            button {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                background-color: transparent;
                color: #fff;
                border: 1px #fff solid;
                border-radius: 25px;
                transition: all 0.5s ease;
                padding: 6.5px 25.5px;
                &:hover,
                &:focus {
                    padding-right: 18.5px;
                    img {
                        margin-left: 14px;
                    }
                }
                img {
                    width: 16px;
                    margin-left: 9px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .passadores {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(50% - 20px);

        .container {
            position: absolute;
        }
    }

    .prev {
        position: absolute;
        cursor: pointer;
        top: calc(50% - 19.5px);
        width: 39px;
        height: 39px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px white solid;
        color: #fff;
        z-index: 2;
        border-radius: 50%;
        @media (max-width: 1365px) {
            left: 15px;
        }
        &:hover,
        &:focus {
            background-color: #fff;
            img {
                filter: brightness(0) saturate(100%);
            }
        }
        img {
            transform: rotate(180deg);
            width: 16px;
            filter: brightness(0) invert(1);
        }
    }

    .next {
        position: absolute;
        right: 15px;
        top: calc(50% - 19.5px);
        cursor: pointer;
        width: 39px;
        height: 39px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px white solid;
        color: #fff;
        z-index: 2;
        border-radius: 50%;
        @media (max-width: 1365px) {
            right: 15px;
        }
        &:hover,
        &:focus {
            background-color: #fff;
            img {
                filter: brightness(0) saturate(100%);
            }
        }
        img {
            width: 16px;
            filter: brightness(0) invert(1);
        }
    }
}

.section-pre-destaque {
    padding: 50px 0px 75px 0px;
    @media (max-width: 991px) {
        padding: 30px 0px;
    }
    .titulo-pre {
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin-bottom: 20px;
        span {
            font-weight: 600;
        }
    }
    .box-pre-destaque-1 {
        background-color: #4b826d;
        height: 204px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px;
        @media (max-width: 991px) {
            margin-bottom: 15px;
        }
        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 35px 15px;
        }
        &:hover,
        &:focus {
            .box-image {
                margin-right: 15px;
                @media (max-width: 1199px) {
                    margin-right: 10px;
                }
            }
        }
        .link-pre {
            color: #ffc505;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            transition: all 0.5s ease;
            img {
                width: 16px;
                margin-left: 9px;
                transition: all 0.5s ease;
            }
        }
        .box-image {
            position: relative;
            background-image: url("/images/elipse-laranja.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            max-width: 130px;
            transition: all 0.5s ease;
            margin-right: 0px;
            will-change: transform;
            .foto-produto {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 170px;
                width: 130px;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }
    }
    .box-pre-destaque-2 {
        background-color: #edac36;
        height: 204px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px;
        @media (max-width: 991px) {
            margin-bottom: 15px;
        }
        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 35px 15px;
        }
        &:hover,
        &:focus {
            .box-image {
                margin-right: 15px;
                @media (max-width: 1199px) {
                    margin-right: 10px;
                }
            }
        }
        .link-pre {
            color: #3a8065;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            transition: all 0.5s ease;
            img {
                width: 16px;
                margin-left: 9px;
                transition: all 0.5s ease;
            }
        }
        .box-image {
            position: relative;
            background-image: url("/images/elipse-verde.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            max-width: 130px;
            transition: all 0.5s ease;
            margin-right: 0px;
            will-change: transform;
            .foto-produto {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 170px;
                width: 130px;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }
    }
    .box-pre-destaque-3 {
        background-color: #ff6720;
        height: 204px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px;
        @media (min-width: 991px) and (max-width: 1199px) {
            padding: 35px 15px;
        }
        &:hover,
        &:focus {
            .box-image {
                margin-right: 15px;
                @media (max-width: 1199px) {
                    margin-right: 10px;
                }
            }
        }
        .link-pre {
            color: #3a8065;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            transition: all 0.5s ease;
            img {
                width: 16px;
                margin-left: 9px;
                transition: all 0.5s ease;
            }
        }
        .box-image {
            position: relative;
            background-image: url("/images/elipse-verde.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            max-width: 130px;
            transition: all 0.5s ease;
            margin-right: 0px;
            will-change: transform;
            .foto-produto {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 170px;
                width: 130px;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }
    }

    .container-carousel {
        position: relative;

        .prev-categorias-home {
            position: absolute;
            left: -58px;
            cursor: pointer;
            top: calc(50% - 19.5px);
            width: 39px;
            height: 39px;
            display: flex;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            background-color: transparent;
            border: 1px #3a8065 solid;
            z-index: 2;
            border-radius: 50%;
            @media (max-width: 1365px) {
                left: -10px;
            }
            &:hover,
            &:focus {
                background-color: #3a8065;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                transform: rotate(180deg);
                width: 16px;
            }
        }
        .next-categorias-home {
            position: absolute;
            right: -58px;
            top: calc(50% - 19.5px);
            cursor: pointer;
            width: 39px;
            height: 39px;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
            display: flex;
            background-color: transparent;
            transition: all 0.5s ease;
            border: 1px #3a8065 solid;
            z-index: 2;
            border-radius: 50%;
            @media (max-width: 1365px) {
                right: -10px;
            }
            &:hover,
            &:focus {
                background-color: #3a8065;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                width: 16px;
            }
        }
    }
}

.section-equipamentos-destaque {
    padding-bottom: 75px;
    @media (max-width: 991px) {
        padding-bottom: 30px;
    }
    .carousel-margem-equipamentos {
        margin-top: 75px;
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
    .box-equipamento {
        background: rgba(238, 219, 184, 0.1);
        border: 1px solid #efefef;
        border-radius: 7px;
        margin-bottom: 15px;
        padding: 18px 35px;
        transition: all 0.5s ease;
        overflow: hidden;

        &:hover,
        &:focus {
            @media (min-width: 992px) {
                border: 1px solid $primary;
                .categoria-equipamento,
                .link-equipamento {
                    opacity: 1;
                }
            }
        }
        .imagem-equipamento {
            padding-top: 155px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 30px;
            transition: all 0.5s ease;
        }
        .titulo-equipamento {
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0em;
            color: #373435;
            transition: all 0.5s ease;
            height: 50px;
            display: flex;
            align-items: center;
            overflow: hidden;
        }
        .categoria-equipamento {
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 23px;
            letter-spacing: 0em;
            color: $primary;
            margin-bottom: 20px;
            transition: all 0.5s ease;
            opacity: 1;
            max-height: 30.4px;
            overflow: hidden;
            @media (max-width: 991px) {
                opacity: 1;
            }
        }
        .link-equipamento {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            color: $primary;
            transition: all 0.5s ease;
            padding-top: 11px;
            opacity: 1;
            @media (max-width: 991px) {
                opacity: 1;
            }
            img {
                filter: brightness(0) saturate(100%);
                width: 16px;
                margin-left: 8px;
            }
        }
    }
    .prev-equipamento-destaque {
        position: absolute;
        left: -58px;
        cursor: pointer;
        top: calc(50% - 19.5px);
        width: 39px;
        height: 39px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px #3a8065 solid;
        z-index: 2;
        border-radius: 50%;
        @media (max-width: 1365px) {
            left: 15px;
        }
        &:hover,
        &:focus {
            background-color: #3a8065;
            img {
                filter: brightness(0) invert(1);
            }
        }
        img {
            transform: rotate(180deg);
            width: 16px;
        }
    }
    .next-equipamento-destaque {
        position: absolute;
        right: -58px;
        top: calc(50% - 19.5px);
        cursor: pointer;
        width: 39px;
        height: 39px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px #3a8065 solid;
        z-index: 2;
        border-radius: 50%;
        @media (max-width: 1365px) {
            right: 15px;
        }
        &:hover,
        &:focus {
            background-color: #3a8065;
            img {
                filter: brightness(0) invert(1);
            }
        }
        img {
            width: 16px;
        }
    }
    .tns-nav {
        position: absolute;
        bottom: -50px;
        z-index: 2;
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            bottom: -30px;
        }
        button {
            background-color: $primary;
            width: 12px;
            height: 6px;
            margin: 0 2px;
            border: none !important;
        }
        .tns-nav-active {
            width: 30px;
            background-color: $secondary !important;
            transition: all 0.5s ease;
        }
    }
    .vermais-produtos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
        @media (max-width: 991px) {
            margin-top: 60px;
        }
        a {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            color: #ff6720;
            transition: all 0.5s ease;
            border-radius: 25px;
            border: 1px solid #ff6720;
            padding: 6.5px 25.5px;
            &:hover,
            &:focus {
                background-color: #ff6720;
                color: #fff;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            img {
                width: 16px;
                margin-left: 9px;
                transition: all 0.5s ease;
            }
        }
    }
}

.section-sobre {
    background: transparent;
    margin-bottom: 40px;
    @media (max-width: 991px) {
        margin-bottom: 30px;
    }
    .box-padding {
        padding: 50px 30px;
        @media (max-width: 991px) {
            padding: 30px;
        }
        .title-sobre {
            font-size: 23px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: $primary;
            position: relative;
            span {
                font-weight: 600;
            }
            &:before {
                content: "";
                position: absolute;
                left: calc(50% - 10px);
                bottom: -15px;
                width: 20px;
                height: 6px;
                background: $secondary;
            }
        }
        .text-sobre {
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: justify;
            color: #373435;
            margin-top: 35px;
        }
        .vermais-sobre {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            a {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: $secondary;
                transition: all 0.5s ease;
                border-radius: 25px;
                border: 1px solid $secondary;
                padding: 6.5px 25.5px;
                &:hover,
                &:focus {
                    background-color: $secondary;
                    color: #fff;
                    img {
                        filter: brightness(0) invert(1);
                    }
                }
                img {
                    width: 16px;
                    margin-left: 9px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
    .box-sobre {
        .imagem-sobre {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 450px;
            transition: all 0.5s ease;
            @media (min-width: 1200px) and (max-width: 1919px) {
                padding-top: 400px;
            }
            @media (max-width: 991px) {
                padding-top: 300px;
            }
            &:hover,
            &:focus {
                transform: translateY(-1px);
            }
        }
    }
}

.section-newsletter {
    padding: 25px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }
    .box-newsletter {
        background-image: url("/images/overlay.png");
        background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        position: relative;
        padding: 0px 100px;
        height: 150px;
        @media (max-width: 991px) {
            height: auto;
            padding: 30px 15px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            padding: 0px 50px;
        }
        .col-size {
            height: 150px;
            @media (max-width: 991px) {
                height: auto;
            }
        }
        .seta {
            position: absolute;
            right: 80px;
            top: -81px;
            width: 250px;
            @media (max-width: 991px) {
                right: calc(50% - 100px);
                top: -65px;
                width: 200px;
            }
            @media (min-width: 1600px) {
                right: 180px;
            }
        }
        .image-newsletter {
            width: 100%;
            @media (max-width: 991px) {
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
            }
            img {
                @media (min-width: 992px) {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                }
                @media (min-width: 1600px) {
                    margin-left: 50px;
                }
            }
        }
        .form-newsletter {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 991px) {
                flex-direction: column;
            }
            @media (min-width: 1600px) {
                padding: 0px 50px;
            }

            .dados-form {
                padding: 0px 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media (min-width: 992px) and (max-width: 1199px) {
                    padding: 0px;
                }
                p {
                    margin: 0;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 35px;
                    letter-spacing: 0em;
                    color: #fff;
                    @media (max-width: 991px) {
                        text-align: center;
                        margin-top: 15px;
                    }
                    span {
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
            button {
                background-color: transparent;
                color: #fff;
                border: 1px #fff solid;
                border-radius: 25px;
                transition: all 0.5s ease;
                margin: 10px;
                padding: 10px 23.5px;
                &:hover,
                &:focus {
                    padding-right: 18.5px;
                    img {
                        margin-left: 14px;
                    }
                }
                img {
                    width: 16px;
                    margin-left: 9px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.slide-wrapper-container-sobre {
    position: relative;

    .next-sobre {
        position: absolute;
        top: calc(50% - 20px);
        right: 50px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px $secondary solid;

        @media (max-width: 1365px) {
            right: 15px;
        }

        &:hover,
        &:focus {
            background-color: $secondary;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }
}
