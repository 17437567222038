.section-titulo-sobre {
    position: relative;
    background-image: url("/images/minibanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    padding: 40px;

    @media (max-width: 991px) {
        margin-bottom: 30px;
    }

    .texto-titulo {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        word-break: break-all;
        margin-top: 0px !important;

        @media (max-width: 991px) {
            padding: 65px 0px;
        }
    }
}

.section-resumo-sobre {
    margin-bottom: 75px;

    @media (max-width: 991px) {
        margin-bottom: 0px;
    }
}

.section-descricao-sobre {
    margin: 40px 0px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }

    .descricao-sobre {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: justify;
        color: $primary;
    }
}

.section-slider-sobre {
    margin-bottom: 125px;

    @media (max-width: 991px) {
        margin-bottom: 60px;
    }

    .box-sobre-individual {
        .imagem-sobre-individual {
            cursor: pointer;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 471px;
            transition: all 0.5s ease;

            @media (min-width: 1200px) and (max-width: 1599px) {
                padding-top: 400px;
            }

            @media (max-width: 991px) {
                padding-top: 300px;
            }

            &:hover,
            &:focus {
                transform: translateY(-1px);
            }
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -50px;
        z-index: 2;
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            bottom: -30px;
        }

        button {
            background-color: $primary;
            width: 12px;
            height: 6px;
            margin: 0 2px;
            border: none !important;
        }

        .tns-nav-active {
            width: 30px;
            background-color: $secondary !important;
            transition: all 0.5s ease;
        }
    }
}

.section-missao-visao-valores {
    .box-88 {
        height: 88px;
        border-right: 1px solid $primary;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-missao-visao-valores {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: justify;
        color: black;
        padding-left: 13px;
    }

    .missao {
        display: flex;
        align-items: center;

        img {
            width: 64px;
            margin-bottom: 8px;
        }
    }

    .visao {
        display: flex;
        align-items: center;
        margin: 75px 0px;

        @media (max-width: 991px) {
            margin: 30px 0px;
        }

        img {
            width: 73px;
            margin-bottom: 8px;
        }
    }

    .valores {
        display: flex;
        align-items: center;

        img {
            width: 74px;
            margin-bottom: 8px;
        }
    }

    .box-video-sobre {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: 474px;

        @media (min-width: 1200px) and (max-width: 1599px) {
            padding-top: 400px;
        }

        @media (max-width: 991px) {
            padding-top: 300px;
            margin-top: 30px;
        }

        .mask-sobre {
            background-image: url("/images/mask-video-sobre.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        .link-video-sobre {
            position: absolute;
            top: calc(50% - 41px);
            left: calc(50% - 41px);
            z-index: 2;

            a {
                &:hover,
                &:focus {
                    img {
                        transform: scale(1.01);
                    }
                }

                img {
                    width: 82px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.section-redes-sociais-verdes {
    margin: 75px 0px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }

    .box-redes-verdes {
        margin-top: 75px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        a {
            .item-verde {
                margin: 0px 13px;
                font-size: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.5s ease;
                width: 76px;
                height: 76px;
                border: 0.806268px solid $secondary;
                border-radius: 50%;
                color: $secondary;

                &:hover,
                &:focus {
                    background-color: $secondary;

                    i {
                        color: white;
                    }
                }

                i {
                    width: 33px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.slide-wrapper-container-sobre {
    position: relative;

    .prev-sobre {
        position: absolute;
        left: 15px;
        cursor: pointer;
        width: 39px;
        height: 39px;
        top: calc(50% - 19.5px);
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        background-color: transparent;
        border: 1px $secondary solid;
        z-index: 2;
        border-radius: 50%;
        &:hover,
        &:focus {
            background-color: $secondary;
            img {
                filter: brightness(0) invert(1);
            }
        }
        img {
            transform: rotate(180deg);
            width: 16px;
        }
    }
    .next-sobre {
        position: absolute;
        right: 15px;
        top: calc(50% - 19.5px);
        cursor: pointer;
        width: 39px;
        height: 39px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px $secondary solid;
        z-index: 2;
        border-radius: 50%;
        &:hover,
        &:focus {
            background-color: $secondary;
            img {
                filter: brightness(0) invert(1);
            }
        }
        img {
            width: 16px;
        }
    }
}
