.section-customers-home {
    .box-logo-customer {
        margin-bottom: 20px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 10px;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;
        position: relative;

        .absoluteBar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0px;
            padding-left: 15px;
            padding-right: 15px;
            opacity: 0;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            will-change: transform;
            overflow: hidden;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .absoluteBar {
                height: 55px;
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        @media (max-width: 991px) {
            margin: 0px 3rem;
        }

        img {
            max-height: 100%;
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        @media (max-width: 991px) {
            display: none !important;
        }

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-customers {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: transparent;
        border: 1px $secondary solid;
        transition: all 0.5s ease;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        &:hover,
        &:focus {
            background-color: $secondary;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }
    }

    .next-customers {
        position: absolute;
        top: calc(50% - 20px);
        right: -45px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: flex;
        border-radius: 100%;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px $secondary solid;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {
            background-color: $secondary;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }
}
