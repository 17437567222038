.footer {
    background-color: $primary;
    padding: 100px 0px 85px 0px;

    @media (max-width: 991px) {
        padding: 30px 0px;
    }

    .content-footer {
        padding: 40px 0px;

        @media (max-width: 991px) {
            padding: 30px 0px 0px 0px;
        }

        .title-content-footer {
            font-size: 15px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: $white;
            margin-bottom: 15px;
        }

        .title-content-footer-com-margem-bottom {
            font-size: 15px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: $white;
            margin-bottom: 25px;
        }

        .title-content-footer-com-margem-top {
            font-size: 15px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: $white;
            margin-top: 60px;
            margin-bottom: 10px;

            @media (max-width: 991px) {
                margin-top: 30px;
            }
        }

        .link-content-footer {
            margin-bottom: 15px;

            a {
                transition: all 0.5s ease;

                font-size: 15px;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: left;

                &:hover,
                &:focus {
                    color: white;
                }

                img {
                    width: 13px;
                    margin-right: 5px;
                }
            }
        }
    }

    .logo-rodape {
        text-align: center;

        img {
            max-width: 250px;
            transition: all 0.5s ease;

            &:hover,
            &:focus {
                transform: scale(1.01);
                transition: all 0.5s ease;
            }
        }
    }

    .box-iframe {
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }

    .box-dados-rodape {
        .margem-titulo-dados {
            margin-top: 48px;

            @media (max-width: 991px) {
                margin-top: 30px;
            }
        }

        .titulo-dados {
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;

            span {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
            }
        }

        .link-dado {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;
            margin-top: 17px;

            @media (max-width: 991px) {
                margin-top: 15px;
            }

            a {
                color: #fff;
                transition: all 0.5s ease;

                &:hover,
                &:focus {
                    color: $secondary;
                }
            }

            img {
                filter: brightness(0) invert(1);
                width: 20px;
                margin-right: 7px;
            }
        }
    }

    .redes-sociais-rodape {
        text-align: center;
        margin-top: 25px;

        @media (max-width: 991px) {
            margin-top: 15px;
            margin-bottom: 30px;
        }

        img {
            filter: brightness(0) invert(1);
            width: 26px;
            transition: all 0.5s ease;
            margin: 0px 4.5px;

            &:hover,
            &:focus {
                transform: translateY(-1px);
                transition: all 0.5s ease;
            }
        }
    }
}

.footer-2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background-color: $secondary;
    color: $primary;
    padding: 11px;

    a {
        color: $primary;
        transition: all 0.5s ease;
        font-weight: 500;

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}

.botao-whatsapp {
    position: fixed;
    right: 15px;
    bottom: 22px;
    z-index: 3;
    opacity: 0;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
        border-radius: 50% !important;
        bottom: 8px !important;
        height: 50px !important;
        width: 50px !important;
    }

    a {
        font-family: "Rubik", sans-serif;
        margin: 5px 0px;
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 25px;
        background-color: #00e492;
        border: 1px #00e492 solid;
        font-size: 18px;
        line-height: 21px;
        padding: 10.5px 25.5px;
        text-decoration: none !important;

        @media (max-width: 991px) {
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 8.8px 12.63px !important;
            text-align: center !important;
        }

        &:hover,
        &:focus {
            text-decoration: none !important;
            background-color: #fff;
            color: #00e492;
            transition: all 0.5s ease;
        }
    }
}

.footer-lgpd {
    position: fixed;
    z-index: 99999;
    max-width: 1230px;
    transition: 0.3s ease-in-out;
    background: #fff;
    bottom: 25px;
    left: 10px;
    right: 10px;
    padding: 20px;
    box-shadow: 0 0 10px #aaa;
    border-radius: 12px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}
.footer-lgpd-opened {
    transition: 0.1s ease-in-out;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}
.btn-lgpd {
    background-color: $primary;
    color: #fff !important;
    text-align: center;
    border: 1px $primary solid;
    transition: all 0.5s ease;
    font-weight: 500;
    width: 300px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    border-radius: 1.25rem;
    text-decoration: none !important;
}
.btn-lgpd:hover,
.btn-lgpd:focus {
    background-color: white !important;
    color: $primary !important;
    transition: all 0.5s ease;
}
.label-lgpd {
    color: white;
}
