.section-categorias {
    .card-category {
        box-sizing: border-box;
        background: rgba(238, 219, 184, 0.1);
        border: 1px solid #efefef;
        border-radius: 7px;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .box-image-category {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;

            img {
                height: 130px;
            }
        }

        .title-category {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            text-align: center;
            color: $primary;

            img {
                filter: brightness(0) saturate(100%);
                transition: all 0.5s ease;
            }
        }

        &:hover {
            .title-category img {
                padding-left: 5px;
            }
        }
    }

    .prev-categorias {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: transparent;
        border: 1px $secondary solid;
        transition: all 0.5s ease;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        &:hover,
        &:focus {
            background-color: $secondary;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }
    }

    .next-categorias {
        position: absolute;
        top: calc(50% - 20px);
        right: -45px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: flex;
        border-radius: 100%;
        background-color: transparent;
        transition: all 0.5s ease;
        border: 1px $secondary solid;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {
            background-color: $secondary;

            img {
                filter: brightness(0) invert(1);
            }
        }

        img {
            width: 16px;
        }
    }
}
