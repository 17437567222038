.section-titulo-equipamento {
    position: relative;
    background-image: url("/images/minibanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
    padding: 40px;

    @media (max-width: 991px) {
        margin-bottom: 30px;
    }

    .texto-titulo {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        word-break: break-all;
        margin-top: 0px !important;

        @media (max-width: 991px) {
            padding: 65px 0px;
        }
    }
}

.section-dados-equipamento {
    margin: 80px 0px;

    @media (max-width: 991px) {
        margin: 30px 0px;
    }

    .foto-equipamento-principal {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 450px;
        cursor: pointer;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            padding-top: 350px;
        }

        &:hover,
        &:focus {
            transform: scale(1.01);
        }
    }

    .dados-equipamento {
        .title {
            font-size: 41px;
            font-style: normal;
            font-weight: 400;
            line-height: 54px;
            letter-spacing: 0em;
            color: #2a1b15;
            margin-bottom: 35px;

            @media (max-width: 991px) {
                margin-bottom: 15px;
                margin-top: 15px;
            }
        }

        .details {
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: justify;
            color: #2a1b15;

            span {
                font-weight: 500;
            }
        }

        .btn-orcamento {
            margin-top: 20px;
            padding: 10px 30px 10px 30px;
            border-radius: 30px;
            background-color: $primary;
            color: white;

            img {
                filter: brightness(0) invert(1);
            }
        }

        .redes-sociais-equipamento {
            display: flex;
            align-items: center;
            margin-top: 35px;

            @media (max-width: 991px) {
                margin-top: 30px;
                justify-content: center;
            }

            .item-rede {
                margin-right: 11px;

                a {
                    button {
                        width: 41px;
                        height: 41px;
                        border: 0.92px solid $primary;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.5s ease;
                        background-color: #fff;
                        color: $primary;
                        font-size: 18px;

                        &:hover,
                        &:focus {
                            background-color: $primary;

                            i {
                                color: #fff;
                            }
                        }

                        i {
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
}
