@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

//Modulos externos
//tiny slider
@import "../../../node_modules/tiny-slider/src/tiny-slider";
//Lightgalery
@import "../../../node_modules/lightgallery/src/sass/lightgallery";
//select2
@import "../../../node_modules/select2/dist/css/select2.css";
@import "../../../node_modules/select2-bootstrap-theme/dist/select2-bootstrap.css";

@import "cabecalho";
@import "rodape";
@import "login";
@import "home";
@import "equipamentos";
@import "sobre";
@import "diferenciais";
@import "beneficios";
@import "categorias";
@import "card-equipamento";
@import "customers";

* {
    font-family: "Poppins", sans-serif;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: $secondary #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.container {
    @media (min-width: 1600px) {
        max-width: 1400px;
    }
}

img {
    image-rendering: -webkit-optimize-contrast;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@font-face {
    font-family: "Carentro DEMO";
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/Carentro_DEMO.otf");
}

.dropdown-toggle::after {
    display: none !important;
}

.title-section {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    color: $primary;
    // border-bottom: 1px solid $secondary;
    padding-bottom: 8px;

    &:before {
        content: "";
        background-color: $secondary;
        width: 57px;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: calc(50% - 28.5px);
    }

    span {
        font-weight: 600;
    }
}

.show-0 {
    display: none;

    @media (max-width: 991px) {
        display: block;
    }
}

.show-992 {
    display: none;

    @media (min-width: 992px) and (max-width: 1199px) {
        display: block;
    }
}

.show-1200 {
    display: none;

    @media (min-width: 1200px) and (max-width: 1599px) {
        display: block;
    }
}

.show-1600 {
    display: none;

    @media (min-width: 1600px) and (max-width: 1919px) {
        display: block;
    }
}

.show-1920 {
    display: none;

    @media (min-width: 1920px) and (max-width: 2559px) {
        display: block;
    }
}

.show-2560 {
    display: none;

    @media (min-width: 2560px) {
        display: block;
    }
}

.pagination {
    margin-bottom: 0px;
    margin-top: 75px;
    justify-content: center;

    @media (max-width: 991px) {
        margin-top: 30px;

        li.page-item {
            display: none;
        }

        .page-item:nth-child(1),
        .page-item:nth-child(2),
        .page-item:nth-child(3),
        .page-item:nth-last-child(2),
        .page-item:last-child,
        .page-item.active {
            display: block;
        }
    }

    .page-item {
        .page-link {
            padding: 0;
            width: 39px;
            height: 39px;
            color: #34775d;
            background-color: transparent;
            border: 1px solid #34775d;
            transition: all 0.5s ease;
            margin: 0px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0em;
            border-radius: 50%;

            &:hover,
            &:focus {
                color: #fff;
                background-color: #34775d;

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    .active {
        .page-link {
            color: #34775d;
            margin: 0px 2.5px;
            background-color: transparent;
            width: 39px;
            height: 39px;
            border: 1px #34775d solid;
            transition: all 0.5s ease;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0em;
            border-radius: 50%;
        }
    }

    .disabled {
        cursor: not-allowed;
    }

    .img-paginator-esquerda {
        transform: rotate(180deg);
        width: 16px;
        transition: all 0.5s ease;
    }

    .img-paginator-direita {
        width: 16px;
        transition: all 0.5s ease;
    }
}

.mask {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url("/images/mask_titulos.png");
    background-size: cover;
}

.newsletter-politica {
    max-width: 366px;
    font-size: 12px !important;
    line-height: 12px !important;
    color: #fff !important;
    margin-top: 15px !important;

    @media (max-width: 991px) {
        margin-bottom: 15px !important;
    }
}


.section-logistica-home {
    margin-bottom: 40px;
}
